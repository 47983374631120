@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@import '../../node_modules/swiper/swiper.min.css';

@font-face {
  font-family: 'DB Heavent';
  src: url('../font/DB-Heavent-v3.2.1.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'DB Heavent';
  src: url('../font/DB-Heavent-Bd-v3.2.1.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Nunito';
  src: url('../font/Nunito-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../font/Nunito-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

body {
  @apply antialiased text-aai-dark-blue text-2xl overflow-y-scroll;
}

.site-footer {
  @apply relative pt-80 bg-[url('../img/footer-bg.svg')] bg-no-repeat bg-right-top bg-cover before:absolute before:left-0 before:bottom-0 before:w-full before:h-full before:bg-gradient-to-t before:from-white before:to-transparent;
}

/* Buttons */
.button {
  @apply inline-flex justify-center items-center text-3xl font-bold rounded-lg p-3 px-6;
}

.button--primary {
  @apply bg-aai-dark-blue text-white hover:bg-aai-blue;
}

.button--tab {
  @apply bg-aai-blue text-white hover:bg-aai-dark-blue;
}

.button--tab.active {
  @apply bg-aai-dark-blue;
}

/* Swiper */
.timeline-prev-button,
.timeline-next-button {
  @apply transition-all duration-150;
}

.timeline-prev-button.swiper-button-disabled,
.timeline-next-button.swiper-button-disabled {
  @apply opacity-0 pointer-events-none;
}

/* Page Sections */
.page-section {
  @apply relative py-16;
}

.page-section--intro {
  @apply min-h-[580px] bg-aai-dark-blue text-white relative py-20 pb-80 after:block after:absolute after:left-0 after:bottom-0 after:w-full after:aspect-[1920/300] after:bg-[url('../img/solid-white.svg')] after:bg-no-repeat after:bg-cover;
}

.page-section--line {
  @apply pb-0 after:block after:w-full after:aspect-[1920/300] after:bg-[url('../img/page-section-line.svg')] after:bg-no-repeat after:bg-[length:100%_100%] after:mt-40;
}

.page-section--solid {
  @apply pb-0 after:block after:w-full after:aspect-[1920/300]  after:bg-no-repeat after:bg-cover after:mt-20;
}

.page-section--solid-blue-bottom {
  @apply after:bg-[url('../img/solid-blue-bottom.svg')];
}

.page-section--solid-white-bottom {
  @apply after:bg-[url('../img/solid-white-bottom.svg')];
}

.page-banner {
  @apply min-h-[760px] grid content-center bg-no-repeat bg-cover py-20 pb-80 relative before:absolute before:inset-0 before:bg-black/40 after:block after:absolute after:left-0 after:bottom-0 after:w-full after:aspect-[1920/300] after:bg-[url('../img/solid-white.svg')] after:bg-no-repeat after:bg-[length:101%_101%];
}

/* Site Header */
.header-nav a {
  @apply inline-block hover:text-aai-red;
}

.header-nav a.menu-active {
  @apply border-b-2 border-b-aai-dark-red;
}

.sub-page-nav a {
  @apply text-aai-blue hover:text-aai-red;
}

.sub-page-nav a.menu-active {
  @apply border-b-2 border-b-aai-dark-red;
}

/* Site Footer */
.footer-menu a {
  @apply hover:text-aai-dark-red;
}

/* Overlap Sections */
.overlap-section {
  @apply pt-[18vw] text-white -mt-[14.2vw] bg-no-repeat bg-cover aspect-[1920/960] relative before:absolute before:inset-0 before:bg-black/40;
  mask-image: url('../img/section-mask.svg');
  mask-position: left top;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}

/* Business Structure */
.table-business-structure-wrapper {
  @apply overflow-hidden rounded-2xl border-2 border-aai-dark-red;
}

.table-business-structure th {
  @apply p-10 px-5 text-white bg-aai-dark-blue border-l-2 border-white first:border-l-0 leading-none;
}

.table-business-structure td {
  @apply p-10 py-16 align-top border-t-2 border-l-2 border-aai-dark-red first:border-l-0;
}

.table-business-structure td:nth-child(1),
.table-business-structure td:nth-child(2),
.table-business-structure td:nth-child(6) {
  @apply whitespace-nowrap font-bold;
}

.table-business-structure td:nth-child(3) {
  @apply whitespace-nowrap;
}

/* Tabs */
.tab-panel {
  @apply hidden;
}

.tab-panel.active {
  @apply block;
}
